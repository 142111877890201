$background-color: var(--background-color);
$primary-text-color: var(--primary-text-color);
$landing-text-color: var(--landing-text-color);
$all-courses-title-color: var(--all-courses-title-color);
$all-courses-section-title-color: var(--all-courses-section-title-color);
$all-courses-section-title-background-color: var(--all-courses-section-title-background-color);
$all-courses-grid-item-background-color: var(--all-courses-grid-item-background-color);
$all-courses-info-color: var(--all-courses-info-color);
$all-courses-item-subtitle-color: var(--all-courses-item-subtitle-color);
$all-courses-item-title-color: var(--all-courses-item-title-color);
$all-courses-item-button-background-color: var(--all-courses-item-button-background-color);
$all-courses-item-button-primary-background-color: var(--all-courses-item-button-primary-background-color);
$all-courses-item-button-color: var(--all-courses-item-button-color);
$all-courses-item-button-primary-color: var(--all-courses-item-button-primary-color);
$footer-background-color: var(--footer-background-color);
$footer-color: var(--footer-color);
$scrollTop-background-color: var(--scrollTop-background-color);
$scrollTop-color: var(--scrollTop-color);
$selected-menu-item-border-color: var(--selected-menu-item-border-color);
$selected-navbar-item-border-color: var(--selected-navbar-item-border-color);
$header-menu-item-color: var(--header-menu-item-color);
$header-selected-menu-item-color: var(--header-selected-menu-item-color);
$myCourse-subjects-items-background-color: var(--myCourse-subjects-items-background-color);
$myCourse-subjects-background-color: var(--myCourse-subjects-background-color);
$myCourse-subjects-color: var(--myCourse-subjects-color);
$primary-button-background-color: var(--primary-button-background-color);
$navbar-myCourses-background-color: var(--navbar-myCourses-background-color);
$navbar-myCourses-color: var(--navbar-myCourses-color);
$navbar-myCourses-selected-color: var(--navbar-myCourses-selected-color);
$login-box-color: var(--login-box-color);
$login-welcome-message: var(--login-welcome-message);
$faq-background-color: var(--faq-background-color);
$faq-button-color: var(--faq-button-color);
$faq-text-color: var(--faq-text-color);
$trainer-container-color: var(--trainer-container-color);
$trainer-container-background-color: var(--trainer-container-background-color);
$add-discussion-button-background-color: var(--add-discussion-button-background-color);
$add-discussion-button-color: var(--add-discussion-button-color);
$certificate-button-background-color: var(--certificate-button-background-color);
$certificate-button-color: var(--certificate-button-color);
$no-course-text-color: var(--no-course-text-color);
$searchBox-inputs: var(--searchBox-inputs);
$Icon_feather-play-color: var(--Icon_feather-play);
$errorpage-icon-color: var(--errorpage-icon-color);
$errorpage-title-color: var(--errorpage-title-color);
$errorpage-text-color: var(--errorpage-text-color);
$errorpage-btn-bg-color: var(--errorpage-btn-bg-color);
$errorpage-btn-text-color: var(--errorpage-btn-text-color);
$myCourse-subjects-lang-toggle-background-color: var(--myCourse-subjects-lang-toggle-background-color);
$myCourse-subjects-lang-toggle-text-color: var(--myCourse-subjects-lang-toggle-text-color);
$myCourse-quizResult-bg-color: var(--myCourse-quizResult-bg-color);
$myCourse-quizResult-title-color: var(--myCourse-quizResult-title-color);
$myCourse-quizResult-text-primary-color: var(--myCourse-quizResult-text-primary-color);
$myCourse-quizResult-success-color: var(--myCourse-quizResult-success-color);
$myCourse-quizResult-failure-color: var(--myCourse-quizResult-failure-color);
$myCourse-quizResult-text-secondary-color: var(--myCourse-quizResult-text-secondary-color);
$myCourse-quizResult-btn-bg-color: var(--myCourse-quizResult-btn-bg-color);
$myCourse-quizResult-btn-text-color: var(--myCourse-quizResult-btn-text-color);
$myCourse-quizResult-secondary-btn-bg-color: var(--myCourse-quizResult-secondary-btn-bg-color);
$myCourse-quizResult-secondary-btn-text-color: var(--myCourse-quizResult-secondary-btn-text-color);

@font-face {
    font-family: "STC Forward";
    src: url("./fonts/stcForwardBold.eot");
    src: url("./fonts/stcForwardBold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/stcForwardBold.woff2") format("woff2"), url("./fonts/stcForwardBold.woff") format("woff"),
        url("./fonts/stcForwardBold.ttf") format("truetype"),
        url("./fonts/stcForwardBold.svg#STC Forward") format("svg");
    font-weight: bold;
}

@font-face {
    font-family: "STC Forward";
    src: url("./fonts/STCForwardMedium.eot");
    src: url("./fonts/STCForwardMedium.eot?#iefix") format("embedded-opentype"),
        url("./fonts/STCForwardMedium.woff2") format("woff2"), url("./fonts/STCForwardMedium.woff") format("woff"),
        url("./fonts/STCForwardMedium.ttf") format("truetype"),
        url("./fonts/STCForwardMedium.svg#STC Forward") format("svg");
    font-weight: 500;
}
  
@font-face {
    font-family: "STC Forward";
    src: url("./fonts/stcForward.eot");
    src: url("./fonts/stcForward.eot?#iefix") format("embedded-opentype"),
        url("./fonts/stcForward.woff2") format("woff2"), url("./fonts/stcForward.woff") format("woff"),
        url("./fonts/stcForward.ttf") format("truetype"), url("./fonts/stcForward.svg#STC Forward") format("svg");
}

body {
    background-color: $background-color !important;
    color: $primary-text-color;
    font-family: "STC Forward";

     .active-section {
         background: #37424d;
         color: $primary-button-background-color !important;

        //  #Icon_feather-play {
        //      fill: $Icon_feather-play-color!important;
        //  }

         #Exclusion_2 {
             fill: $primary-button-background-color !important;
         }

         button {
            padding: 12px 0 !important;
        }
     }

    .iframe-active-video,
    .iframe-active-iframe {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }

    .iframe-active-video::before,
    .iframe-active-iframe:before {
        padding-top: 56.25%;
        display: block;
        content: "";
    }

    .iframe-active-video iframe,
    .iframe-active-iframe iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    .login-wrapper {
        padding: 30px 0;
    }

    .login-image {
        margin-top: 33px;
    }

    .flex-1 {
        flex: 1 !important;
    }

    .login-box {
        background-color: $login-box-color;
        width: 543px;
        padding: 30px;
        padding-bottom: 40px;
        border-radius: 2px;
        margin-left: auto;
        box-shadow: 0px 3px 8px #1D252D0D;
    }

    .login-footer {
        width: 543px;
        margin-left: auto;
        padding: 30px;
        color: #8E9AA0;

        .left {
            font-weight: 500;
            a {
                color: #8E9AA0;
            }
        }
    }

    .login-field {
        position: relative;
        svg {
            position: absolute;
            right: 23.59px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            cursor: pointer;
        }
    }

   .login-field.is-valid {
        svg {
            right: 50px;
        }
    }

    .login-field.is-invalid {
        svg {
            right: 23.59px;
        }
    }
    
    @media (max-width: 1199px) {
        .login-box {
            margin: 20px auto 0;
        }
        .account-box {
            margin: auto;
            width: auto !important;
            padding-left: 20px !important;
        }
        .profile-box {
            margin: auto;
            width: auto !important;
        }
        .login-footer {
            margin: auto;
        }

        .login-image {
            justify-content: center;
        }
    }

    @media (min-width: 992px) {

        span::-webkit-scrollbar, ol::-webkit-scrollbar, ul::-webkit-scrollbar,
         pre::-webkit-scrollbar, div::-webkit-scrollbar {
            width: 16px !important;
        }

        span::-webkit-scrollbar-track, ol::-webkit-scrollbar-track, ul::-webkit-scrollbar-track,
        pre::-webkit-scrollbar-track, div::-webkit-scrollbar-track {
            background-color: #1D252D !important;
          }

        span::-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, 
        pre::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
            border-radius: 1px !important;
            border: 5px solid transparent !important;
            background-clip: content-box !important;
            background-color: #03BB86 !important;
        }
    }

    @media (max-width: 594px) {
        .login-image {
            justify-content: flex-start !important;
        }
    }

    @media (max-width: 612px) {
        .login-box {
            width: 100%;
        }
        .login-footer {
            width: 100%;
        }
    }

    .login-message {
        color: #1D252D;;
        font-size: 36px;
    }
    .login-wel-message{
        color:#4F008C;
        font-size: 24px;
    }
    .login-gray-message{
        color:#8E9AA0;
        font-size: 24px;
    }

    @media (min-width: 490px) and (max-width: 594px) {
        .login-message {
            font-size: 28px;
           
        }
        .login-wel-message{
            font-size: 18px;
        }
        .login-gray-message{
            font-size: 18px;
        }
       
    }

    .landing {
        padding: 25px 0;
        h1 {
            font-size: 58px;
            color: $landing-text-color;

            span.wel-text {
                margin-right: 14px;
            }

            img.logo {
                margin: -27px 14px 0 0;
            }
        }

        img#illusRight {
            max-width: 100%;
        }

        .marketIcons {
            margin-top: 84px;

            .marketIcon:first-child {
                margin-right: 12px;
            }
            .marketIcon {
                svg{
                    height: 65px;
                    width: 213px;
                    max-height: 65px !important;
                    border: 3px solid #8e9aa0;
                }
                text:first-child{
                    font-size: 10px !important;
                }
                text:last-child{
                    font-size: 18px !important;
                }
            }
        }
    }

    .landing.noCourse {
        padding: 75px 0;
        margin-top: 55px;
        h1 {
            font-size: 28px;
            color: $landing-text-color;
        }
        p {
            font-size: 16px;
            color: $no-course-text-color;
            margin: 20px 0 0 0;
        }
        button {
            background: $primary-button-background-color;
            color: #ffffff;
            font-size: 24px;
            padding: 10px 40px;
            border: 0;
            margin: 25px 0 0 0;
        }
    }

    .allCourses {
        margin-top: 20px;

        .navbarMyCourses {
            border-top: 0;
        }

        .allCoursesTitle {
            font-size: 32px;
            color: $all-courses-title-color;
            font-weight: bold;
        }

        .allCoursesIcons {
            display: flex;
            align-items: center;

            .allCoursesIcons_inner {
                margin-left: 0;
            }

            span {
                margin-right: 10px;
            }

            select {
                margin-left: 10px;
                padding: 0 5px;
                background: inherit;
                color: inherit;
                border: 0;
            }
        }

        .allCoursesIcons.myCourses {
            margin-top: 20px;
        }

        .grid path {
            fill: rgba(0, 0, 0, 0) !important;
        }

        .play {
            margin-right: 40px;
            svg {
                width: 21px !important;
                height: 27px !important;   
                path {
                    fill: $Icon_feather-play-color !important;
                }
            }
        }

        .section {
            height: 66px;
            background-color: $all-courses-section-title-background-color;
            color: $all-courses-section-title-color;
            display: flex;
            align-items: center;
            margin-top: 30px;
            position: absolute;
            left: 0;
            .title {
                font-size: 24px;
                font-weight: bold;
                padding-left: 5px;
            }
        }

        .allCoursesGrid.noCourse {
            grid-template-columns: 1fr !important;
        }

        .myCoursesGridTop{
            margin-top: 95px !important;
        }
        .allCoursesGrid {
            display: grid;
            grid-gap: 12px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            margin: 120px 0 20px;
            padding: 0;

            .allCoursesGridItem {
                background-color: $all-courses-grid-item-background-color;
                padding: 15px;

                .allCoursesGridItemImage {
                    width: 100%;
                    height: 158px;
                    background: rgba(0, 0, 0, 0.1);
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: top center;
                }

                path {
                    fill: rgba(0, 0, 0, 0) !important;
                }

                .allCoursesGridInfo {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    color: $all-courses-info-color;
                    font-size: 14px;

                    svg {
                        margin-right: 5px;
                    }
                }

                .allCoursesGridItemSubtitle {
                    color: $all-courses-item-subtitle-color;
                    font-size: 12px;
                    margin-top: 20px;
                    min-height: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .allCoursesGridItemTitle {
                    color: $all-courses-item-title-color;
                    font-weight: bold;
                    font-size: 18px;
                    margin-top: 12px;
                    height: 60px;
                    max-height: 60px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                button.allCoursesGridItemButton {
                    border-radius: 2px;
                    width: 100%;
                    height: 36px;
                    margin-top: 20px;
                    border: 0;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 36px;
                    background-color: $all-courses-item-button-background-color;
                    color: $all-courses-item-button-color;
                }

                button.primary {
                    background-color: $all-courses-item-button-primary-background-color !important;
                    color: $all-courses-item-button-primary-color !important;
                }
            }
        }

        @media (min-width: 801px) and (max-width: 1200px) {
            .allCoursesGrid {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        @media (min-width: 501px) and (max-width: 800px) {
            .allCoursesGrid {
                grid-template-columns: 1fr 1fr;
            }
        }

        @media (max-width: 500px) {
            .allCoursesGrid {
                grid-template-columns: 1fr;
            }
        }

        @media (min-width: 993px) {
            //large, x-large
            .allCoursesIcons_inner {
                margin-left: auto !important;
            }
        }
    }
}

.course-detail-content {
    margin-top: 66px !important;
    .certificatesTab {
        margin-top: 82px !important;
    }
}

.footer {
    background-color: $footer-background-color;
    color: $footer-color;
    font-size: 12px;
    span {
        color: #8e9aa0 !important;
    }
}

.scrolltop {
    background-color: $scrollTop-background-color;
    color: $scrollTop-color;
}

.header-fixed[data-kt-sticky-header="on"] .header {
    background-color: $background-color !important;
    height: 80px !important;
}

.ml-auto {
    margin-left: auto;
}

.menu-item.headerMenuItem {
    width: 160px;
    margin: 0 !important;
    margin-left:50px !important;
    border-radius: 0;
    .menu-title {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: $header-menu-item-color !important;
    }
}

.menu-item.selectedItem {
    .menu-title {
        color: $header-selected-menu-item-color !important;
    }
}

.selectedWithBorder {
    margin-top: 7px;
}

.selectedWithBorder::after {
    content: "";
    display: block;
    position: relative;
    top: 0;
    width: 50%;
    border-top: 7px solid $selected-menu-item-border-color;
}

.menu-item.headerMenuItem .menu-link:hover {
    background-color: inherit !important;
}

.toolbarBell path {
    fill-opacity: 0 !important;
    stroke: #b5bec3;
}

.toolbarGrid path {
    fill: #b5bec3 !important;
}

.toolbarName {
    color: $header-menu-item-color !important;
    font-size: 18px !important;
}

.toolbarArrow {
    svg {
        width: 18px !important;
        height: 10px !important;
        margin: 0 23.52px;
        path {
            fill: $header-menu-item-color !important;
        }
    }
}

.error-icon {
    font-size: 4rem;
    line-height: 2;
}

.errorBox,
.indicatorBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh + 70px);
    margin-top: -70px;
    flex-direction: column;
    font-weight: 400 !important;
}

.pageContainer {
    padding:0px 0px 20px 0px;

    .coursePreview {
        width: 100%;
        display: flex;
        margin: 20px 0 0 0;
        .quizResultsContainer {
            display: flex;
            align-items: center;
            // width: 100%;
            height: 100%;
            padding: 24px 0;
            background: var(--myCourse-quizResult-bg-color);
            color: var(--primary-text-color);
          }
      
          .quizResultsTitle {
            font-size: 36px;
            font-weight: 600;
            color: $myCourse-quizResult-title-color;
          }
      
          .quizResultsSubTitle {
            font-size: 16px;
            font-weight: 600;
            color: $myCourse-quizResult-text-primary-color;
          }
      
          .quizResultsSuccessText {
            font-size: 28px;
            font-weight: 600;
            color: $myCourse-quizResult-success-color;
          }
      
          .quizResultsFailureText {
            font-size: 28px;
            font-weight: 600;
            color: $myCourse-quizResult-failure-color;
          }
      
          .quizResultsSubText {
            font-size: 16px;
            color: $myCourse-quizResult-text-secondary-color;
          }
      
          .quizResultsButton {
            background-color: $myCourse-quizResult-btn-bg-color !important;
            color: $myCourse-quizResult-btn-text-color !important;
            border: 0;
            font-size: 18px;
            font-weight: 600;
            border-radius: 8px;
            &.success{
            background-color: $primary-button-background-color !important;
            color: #ffff !important;
            padding: 20px;
            }
      
            &.secondary{
              background-color: $myCourse-quizResult-secondary-btn-bg-color !important;
              color: $myCourse-quizResult-secondary-btn-text-color !important;
              margin-left: 28px;
      
              svg{
                margin-right: 8px;
      
                path{
                  fill: $myCourse-quizResult-secondary-btn-text-color !important;
                }
              }
            }
          }
        .accordion {
            button.withArrow::before {
                margin-top: -5px;
                flex-shrink: 0;
                width: 20px;
                height: 11px;
                margin-left: 0;
                margin-right: 10px;
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.828' height='11.414' viewBox='0 0 20.828 11.414'%3E%3Cpath id='Icon_feather-chevron-right' data-name='Icon feather-chevron-right' d='M13.5,27l9-9-9-9' transform='translate(28.414 -12.086) rotate(90)' fill='none' stroke='%23fafafa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E");                
                transition: transform 0.2s ease-in-out;
                transform: rotate(-90deg);
            }

            .active-unitindex::before {
                transform: rotate(0deg) !important;
            }
        }

        .introAccordion {
            svg {
                //margin-right:10px;
                path {
                    fill: white !important;
                }
            }
        }

        // .subjectsAccordion:hover {
        //     color: $primary-button-background-color !important;

        //     #Icon_feather-play {
        //         fill: $Icon_feather-play-color !important;
        //     }

        //     #Exclusion_2 {
        //         fill: $primary-button-background-color !important;
        //     }
        // }

        .subjectsAccordion {
            button {
                text-align: left;
                background: transparent;
                color: white;
                border: 0;
                padding: 12px 0;
                margin: 0;
                border-radius: 2px;
                
                .unit-text {
                    padding: 0 12px;
                }
                svg {
                    path {
                        fill: white !important;
                    }
                }
                .tickIcon{
                    svg {
                        width: 18.055px !important;
                        height: 12.882px !important;
                        margin-top: -5px;
                        path {
                            fill: transparent !important;
                        }
                    }
                }

                .play-unit {
                    position: absolute;
                    right: 0%;
                    svg {
                        margin-right: 0 !important;
                        margin-top: 4px;
                        width: 18px !important;
                        height: 18px !important;
                    }
                }

                span.left-icon {
                    min-width: 18.05px !important;
                }
            }
        }

        .collapse:last-child button {
            padding-bottom: 24px !important;
        }

        .coursePreview_Actions {
            flex: 1;
            background: rgba(0, 0, 0, 0.1);
            background-size: cover;
            background-position: center;

            .coursePreview_ActionsCover {
                width: 100%;
                height: 100%;
                background: rgb(0, 0, 0);
                background: linear-gradient(
                    10deg,
                    rgba(29, 37, 45, 1) 0%,
                    rgba(55, 62, 69, 0.69) 10%,
                    rgba(57, 71, 85, 0.24) 100%
                );
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
            }

            .coursePreview_ActionsInfo {
                position: relative;
                padding: 0 30px 30px 30px;
                h1 {
                    color: white;
                    font-size: 36px;
                }
                .coursePreview_ActionsButtons {
                    display: flex;
                    margin-top: 25px;

                    button {
                        height: 62px;
                        line-height: 62px;
                        padding: 0 50px;
                        border-radius: 2px;
                        border: 0;
                        font-size: 18px;
                        background: rgba(0, 0, 0, 0);
                        color: white;
                    }

                    button.play {
                        font-size: 20px;
                        line-height: 24px;
                        background: $primary-button-background-color;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 35px !important;
                            height: 35px !important;
                            path {
                                fill: white !important;
                            }
                        }

                        span {
                            line-height: normal;
                            margin-right: 10px;
                        }
                    }

                    button.addFavorite {
                        padding: 0 25px;
                        path {
                            fill: rgba(0, 0, 0, 0) !important;
                            stroke: white !important;
                        }
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .coursePreview_Subjects {
            min-width: 380px;
            max-width: 380px;
            background-color: #4c5b69;
            color: $myCourse-subjects-color;
            //height: 551px;
            overflow-y: scroll;
            position: relative;

            .title-container {
                background: #394755;
            }

            .title {
                font-size: 28px;
                font-weight: bold;
                padding: 30px;
            }

            .coursePreview_Items {
                position: absolute;
                width: 100%;
            }

            .coursePreview_Items .card {
                background-color: $myCourse-subjects-items-background-color;
                margin: 0;
                border-radius: 0;

                .card-header {
                    border-bottom: 2px solid #394755;
                }

                button {
                    position: relative;
                    font-size: 16px !important;
                    line-height: 22px;
                    display: flex;
                    align-items: flex-start;
                    color: #FAFAFA !important;
                    text-align: inherit;
                }
            }
        }
    }

    .myCourse_overview {
        h2 {
            color: $landing-text-color;
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 32px;
        }

        h2.long::after {
            content: "";
            display: block;
            position: relative;
            top: 0;
            width: 80px;
            border-top: 7px solid $selected-menu-item-border-color;
        }

        h2.short::after {
            content: "";
            display: block;
            position: relative;
            top: 0;
            width: 50px;
            border-top: 7px solid $selected-menu-item-border-color;
        }

        p {
            color:  $primary-text-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 32px;
        }

        .myCourse_courseOverview,
        .myCourse_whoShouldAttend {
            color:  $primary-text-color;
            font-size: 16px;
            line-height: 24px;
            margin-top: 67px;

            ul {
                margin: 0;
                list-style-type: none;
                font-size: 20px;
                color:  $primary-text-color;
            }
            ul > li {
                text-indent: -5px;
            }
            ul > li:before {
                content: "-";
                text-indent: -5px;
                font-size: 60px;
                line-height: 48px;
                font-weight: bold;
                margin-right: 15px;
            }
        }

        .columnRight {
            img {
                margin: 56px 0px;
                max-width: 100%;
                width: 100%;
            }

            .myCourse_FAQ {
                .myCourse_FAQItems {
                    margin-top: 36px;
                    .card {
                        background-color: $faq-background-color;
                        color: $faq-text-color;
                        margin-bottom: 20px;
                        .card-header {
                            border: 0;
                        }
                        button {
                            color: $faq-text-color;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: left;
                            align-items: center;
                            div.withEllipsis {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                color: $landing-text-color !important;
                            }
                        }
                        .card-body {
                            font-size: 14px;
                            line-height: 19px;
                            color: #8e9aa0 !important;
                            padding: 14px 30px 18px;
                        }
                    }
                }
            }
        }

        .trainerContainer {
            background: $trainer-container-background-color;
            border-radius: 2px;
            padding: 30px;
            margin-top: 50px;
            .trainerName {
                font-size: 24px;
            }
            p {
                margin: 0 40px;
                font-size: 20px;
                line-height: 32px;
                color: $trainer-container-color !important;
            }
            img {
                min-width: 0;
            }
        }
    }

    .discussionTab {
        margin: 56px 0;

        .noDissussionMessage {
            color: $landing-text-color;
            font-size: 24px;
        }

        button.addDiscussionButton {
            background-color: $add-discussion-button-background-color;
            color: $add-discussion-button-color;
            font-size: 14px;
            border: 0;
            width: 210px;
            height: 36px;
            line-height: 36px;
            margin-top: 40px;
        }
    }

    .certificatesTab {
        margin: 10px 0px;

        .certificate {
            padding: 0;

            button.certificateButton {
                width: 100%;
                background-color: $certificate-button-background-color;
                box-shadow: 0px 3px 8px #1D252D0D;
                border-radius: 2px;
                color: $certificate-button-color;
                font-size: 16px;
                line-height: 32px;
                height: 66px;
                border: 0;
                margin: 10px 0px;
                padding: 0 50px;

                svg {
                    width: 27px;
                    height: 27px;
                    path {
                        fill: rgba(0, 0, 0, 0);
                        stroke: $certificate-button-color;
                    }
                }
            }
        }
    }

    .certificate:nth-child(2n-1) {
        padding-right: 10px;
    }

    .certificate:nth-child(2n) {
        padding-left: 10px;
    }
}

.dropdownHeader {
    background-color: #4c5b69;
    border-radius: 0px;
    padding-bottom: 0px !important;
}
.dropdownHeader .menu-item {
    padding: 0px 6px 7px 6px !important;
}
.dropdownHeader a.menu-link {
    font-size: 18px;
    color: #ffffff !important;
    display: block !important;
}
.dropdownHeader a.menu-link:hover {
    background-color: #fafafa !important;
    color: #8E9AA0 !important;
    border-radius: 2px !important;
}

.span-first {
    display: block;
}
.span-second {
    display: block;
    font-size: 12px;
}

.title-login-form {
    color: #8E9AA0;
    font-size: 32px;
    font-weight: bold;
}

.account-box .title-login-form {
    font-size: 28px;
}

#input-login {
    background-color: #fafafa;
    border-radius: 0px;
    color: #8E9AA0;
    border: 0px;
    font-weight: 400;
}
#input-login::placeholder {
    color: #8E9AA0;
}

.form-control.is-invalid {
    border: 2px solid #F4B249 !important;
    background-image: none !important;
}


#kt_sign_in_submit {
    width: 40% !important;
    border-radius: 0px;
    background-color: #00c48c;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #FAFAFA;
}

.forgot-password-text {
    color: #8E9AA0 !important;
    font-size: 18px !important;
    align-items: center !important;
    margin-top: 0.75rem;
    font-weight: 500 !important;
}

.error-alert {
    color: #F4B249;
    font-size: 14px;
    font-weight: 500;
}
.navbarMyCourses {
    background-color: $navbar-myCourses-background-color; 
    color: $navbar-myCourses-color;
    width: 100%;
    height: 66px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;

    .container {

        .navbarMyCourses_Item {
            padding: 0 15px;
            cursor: pointer;
            font-size: 18px;
            span {
                padding: 0 15px;
            }
        }
    }
    
}

.navbarMyCourses_Item.selectedWithBorder {
    color: $navbar-myCourses-selected-color !important;
}

.navbarMyCourses_Item.selectedWithBorder::after {
    border-color: $selected-navbar-item-border-color !important;
}

.progressC {
    width: 65px;
    height: 65px;
    line-height: 65px;
    background: none;
    box-shadow: none;
    position: relative;
}

.progressC:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid #4c5b69;
    position: absolute;
    top: 0;
    left: 0;
}

.progressC > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
}

.progressC .progress-left {
    left: 0;
}

.progressC .progressBar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
}

.progressC .progress-left .progressBar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //transform: rotate(180deg);
}

.progressC .progress-right {
    right: 0;
}

.progressC .progress-right .progressBar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    //transform: rotate(180deg);
}

.progressC .progress-value {
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: none;
    font-size: 12px;
    color: #8E9AA0;
    line-height: 60px;
    text-align: center;
    position: absolute;
    top: 5%;
    left: 5%;
}

.progressC.blue .progressBar {
    border-color: #fff;
}

.progress-completed {
    margin: 5px 0px;
    font-size: 12px;
    color: #8E9AA0;
}

.courseProgress {
    margin-top: 20px;
}

.progress {
    background-color: #4c5b69;
    border-radius: 0;
    height: 10px;

    .progress-bar {
        background-color: #ffffff;
    }
}

.blurContent {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.mobile-menu {
    display: none;
    position: fixed;
    z-index: 2;
    height: 92px;
    bottom: 0px;
    width: 100%;
    background: $background-color;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;

    a {
        color: #8E9AA0 !important;
    }

    a.selectedWithBorder {
        color: #1D252D !important;
    }
}

#userDropdownMobile {
    display: none;
    cursor: pointer;
}

.mobileMenuContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 65px);
    background: $background-color;
    z-index: 99;
    align-items: center;
    opacity: 0;
	animation: fadeIn 0.5s ease-in both;
    div {
        font-size: 20px;
        font-weight: 500;
        padding: 0 0  24px;
        span {
            font-size: 12px;
            opacity: 0.9;
            font-weight: 400;
        }
    }

    .welcome-text {
        padding: 74px 0 48px;
        color: $landing-text-color;
    }

    a {
        color: #8E9AA0;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translate3d(0, -20%, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }    
}

.title-account {
    margin-top: 7px;
    width: 79px !important;
}

.title-account::after {
    content: "";
    display: block;
    position: relative;
    top: 0;
    width: 79px !important;
    border-top: 7px solid $selected-menu-item-border-color;
}

.account-box {
    background-color: $login-box-color;
    width: 543px;
    padding: 30px;
    padding-bottom: 40px;
    border-radius: 2px;
    margin-left: auto;
    margin-left: 5rem;
    min-height: 379px;
    height: auto;
}

.menu-sub {
    background-color: #8E9AA0;
    color: $landing-text-color;
}

.searchBox {
    .searchInput,
    .searchInput:focus,
    a {
        color: $searchBox-inputs !important;
    }
}

#alert {
    color: #f5f5f5 !important;
}
.alert-badge {
    background-color: #394755 !important;
    color: #b5bec3 !important;
}

.tickIcon {
    polygon {
        fill: #00c48c;
    }
}

.header {
    border: 0 !important;
}
.div-faqPlus {
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-left: 15px;
}
.svg-icon svg {
    width: 21px;
    height: 21px;
}

.sortSelect {
    padding-right: 40px;
    color: #FAFAFA;
    border: 0;
    outline: 0;
    cursor: pointer;
    font-size: 16px;
    overflow: hidden;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.035' height='10.231' viewBox='0 0 18.035 10.231' class='mh-50px'%3E%3Cpath id='Icon_awesome-caret-down' data-name='Icon awesome-caret-down' d='M2.008,13.5H17.616a1.211,1.211,0,0,1,.855,2.069l-7.8,7.807a1.216,1.216,0,0,1-1.717,0l-7.8-7.807A1.211,1.211,0,0,1,2.008,13.5Z' transform='translate(-0.794 -13.5)' fill='%23fff'%3E%3C/path%3E%3C/svg%3E") no-repeat right;
    -webkit-appearance: none;
    background-position: right 5px center;

    option {
        background: #fff;
        color: $landing-text-color;
    }
}

.sortSelectMobile {
    color: #8E9AA0;
    border: 0;
    outline: 0;
    margin-top: 120px !important;
    font-size: 16px;
    width: auto;
    background: none;
    option {
        background: #4c5b69;
        color: #fff;
    }
}

.profile-title{
    font-weight:bold;
    color : #1D252D !important;
    font-size: 28px;
}

.changeLanguage{
    display:flex;
    align-items: center;
    font-size:18px;
    color:$header-menu-item-color !important;
    margin-left:10px;
    cursor:pointer;
}

.changeLanguageAuth{
    cursor: pointer;
}
.btn-link{
    color: $landing-text-color !important;
}
.hour-icon  {
    svg {
        path {
            stroke: #8E9AA0 !important;
        }
    }
} 
.pageContainer .myCourse_overview .myCourse_courseOverview ul > li:before {
    color: #03BB86 ;

}
.profile-box{
    p {
        font-size: 16px;
        font-weight: 400;
        color: #8E9AA0;
    }

    .user-name-pro {
        font-size: 18px;
        font-weight: 500;
    }
}

.search-icon-header{
    svg {
       
        path {
            fill: #8E9AA0!important;
        }
        
        rect {
            fill: #8E9AA0 !important;
            opacity: 1 !important;
        }
    }
}
.searchInput::placeholder , .tm-color{
    color:$searchBox-inputs!important;
}
.searchBoxSVG , .not-found {
    svg {
       
        path {
            fill: #ffffff !important;
        }
        
        rect {
            fill: #ffffff !important;
            opacity: 1 !important;
        }
    }
}
@import "./popleads_mobile.scss";
@import "./popleads_rtl.scss";


.simpleModalBody {
    color: #464e5f;
    margin-top: 10px;
}

.simpleModalTitle {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #00C48C;
    font-size: 2rem;
}
.isPasswordChangeModalBody{ 
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.successOkBtn{
    background: #00C48C !important;
}

.coursePreview_noContent{
    color:#ffffff;
    margin:20px;
    text-align: center;
    cursor: pointer;

    &:hover{
        text-decoration: underline;
    }
}

.errorPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 16px;
    svg {
        display: block;
        width: 432px;
        height: 164px;
        max-height: unset !important;
        margin-top: 84px;
        margin-bottom: 56px;
        path {
            fill: $errorpage-icon-color !important;
        }
    }
    h1 {
      font-size: 58px;
      font-weight: 600;
      color: $errorpage-title-color;
    }
    p {
      font-size: 16px;
      color: $errorpage-text-color;
      margin: 32px 0;
    }
    button {
      background: $errorpage-btn-bg-color;
      color: $errorpage-btn-text-color;
      font-size: 14px;
      padding: 8px 16px;
      border: 0;
      border-radius: 2px;
    }
  }

// Switch for course content language change
.languageSwitch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 25px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid $myCourse-subjects-lang-toggle-background-color;
        border-radius: 34px;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        content: attr(data-language);
        font-size: 12px;
        font-weight: bold;
        color: $myCourse-subjects-lang-toggle-text-color;
        height: 32px;
        width: 32px;
        left: -8px;
        top: -5px;
        border-radius: 50%;
        background-color: $myCourse-subjects-lang-toggle-background-color;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(32px);
        -ms-transform: translateX(32px);
        transform: translateX(32px);
    }
  
    //   input:checked + .slider {
    //     background-color: xxx;
    //   }
}
